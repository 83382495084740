import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {

  injectedData: any;
  constructor( private dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any) { 
      this.injectedData=data;
    }

  ngOnInit(): void {

  }

  save() {
    this.dialogRef.close("Ok");
  }

  close() {
      this.dialogRef.close();
  }

}
