///// <reference types="arconnect" /> 

import { Injectable } from '@angular/core';
declare let require: any;
// const Arweave = require('arweave');
import  Arweave from 'arweave';
// import { PermissionType } from 'arweave/node/transactions';
import { timeEnd } from 'console';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { WalletDetails } from '../models/wallet-details';
import { readContract, interactWrite, interactWriteDryRun } from 'smartweave';
import { PermissionType } from 'arconnect';
import * as ArweaveUtils from "arweave/node/lib/utils";

// Replace this Id with the contract you want to read.

const realCommunityIdPBt = "0GRLIbU0jmipa-jBuNjrshdBsrw96HTSVdCyLniF1Sg";
const testCommunityIdPBot = "_iiAhptMPS95AxLXjX7bMPBZ5gyh_X2XXmrQeootpFo";
const targetWalletAddress = environment.targetWalletAddress; // 'ivuY1YVWN-kMWNMwV6smGTmOAL-EtiLe4sZ2T3S54_E';

@Injectable({
  providedIn: 'root'
})
export class ArweaveService {
  arweave;
  isWalletLoaded$ =new BehaviorSubject(false);
  isWalletReady$ =new BehaviorSubject(false);

  // arLocalWalletDb: WalletDB ;

  pbtBalances: any ;
  pbtBalance$: BehaviorSubject<any> =new BehaviorSubject(null);;

  walletDetails$: BehaviorSubject<WalletDetails> =new BehaviorSubject(null);

  constructor() {
    this.arweave = Arweave.init({
      host: environment.arweaveHost,// Hostname or IP address for a Arweave host
      port: environment.arweavePort,          // Port
      protocol: environment.arweaveProtocol,  // Network protocol http or https
      timeout: 20000,     // Network request timeouts in milliseconds
      logging: false,     // Enable network request logging
    });

    this.loadPBTBalance();
  }

  async setWalletLoaded(status: boolean){
    this.isWalletLoaded$.next(status);
    if(status){
      let perms = await window.arweaveWallet.getPermissions();
      // let signature2 = await window.arweaveWallet.signature(null,{
      //   name: 'ECDSA',
      //   hash: 'SHA-256'
      // }); 
      // let signature2 = await window.arweaveWallet.signature(data,'ECDSA'); 
      // let signature2 = await window.arweaveWallet.signature(null,{
      //   name: "ECDSA",
      //   hash: {name: "SHA-384"},
      // }); 
                    
      if (perms.length == 4) {
          await this.loadWalletDetails();
          this.isWalletReady$.next(true);


      }
      
    }
  }

  async loadWalletDetails(){
    let address = await window.arweaveWallet.getActiveAddress();
    
    this.walletDetails$.next({
      address: address,
      permissions: ['READ'],
      balance: this.arweave.ar.winstonToAr( await this.arweave.wallets.getBalance(address) )
    });

    const balance = this.pbtBalances[address];
    this.pbtBalance$.next(balance);
    
    // this.setWalletLoaded(true);
    
  }


  async loadPBTBalance(){
    
    const balancesString = localStorage.getItem('balances');

    if(balancesString && balancesString.length>0){
      this.pbtBalances=JSON.parse(balancesString);
    }else{
      // Replace this Id with the contract you want to read.
      const contractId = '0GRLIbU0jmipa-jBuNjrshdBsrw96HTSVdCyLniF1Sg'

      const contractState = await readContract(this.arweave, contractId).catch(err=>{
        console.error('Error getting PBT balance:', err)
      })
      // contractState is the latest state of the contract.
      // assuming it's a PST token, dump all the balances to the console:
      console.log(contractState.balances)
      this.pbtBalances=contractState.balances;
      localStorage.setItem('balances',JSON.stringify(this.pbtBalances));
     
    }
    
    

  }

  async getPBTBalance(){
    return this.pbtBalance$.value;
    // if(this.pbtBalances){
    //   const address = await window.arweaveWallet.getActiveAddress();
    //   const balance = this.pbtBalances[address];
    //   console.log('PBT balance:', balance)
    //   return balance;
    // }else{
    //   await this.loadPBTBalance();
    // }
    
  }

  

  async connecToWallet(permissions: Array<PermissionType> ){
    await window.arweaveWallet.connect(permissions, { name: "wAR BSC" });
      
    try {
      await this.loadWalletDetails();
    } catch (err) {
        console.error('Error Loading Wallet details :', err);
        alert('Error Loading arConnect Wallet details ')
    }
  }


}


