import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

import Web3 from 'web3';

const TruffleContract = require('@truffle/contract');

declare let require: any;
declare let window: any;
// const tokenAbi = require('../../assets/wAR.json');
const tokensAbi = new Map();
tokensAbi.set("wAR", require('../../assets/wAR.json'));
tokensAbi.set("PBT", require('../../assets/wPBT.json'));

@Injectable({
  providedIn: 'root'
})
export class BSCService {

  private account: any = null;
  private readonly provider: any;
  public walletEnabled$ =new BehaviorSubject(false);

  private bscFee = "0.003"; // "0.0030";

  public validNetworks = [
    56,
    97
  ];

  constructor() {
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
        // window.ethereum.enable();
        // this.web3 = window.web3;
        this.provider = window.web3.currentProvider;
        // window.web3.eth.getChainId().then((chainId:any )=>{
        //   // console.log('ChainId: ', chainId);
        // })
        
    }
    else{
        setTimeout(()=>{
            alert('Non-Binance Smart Chain browser detected. Install MetaMask or switch to BSC');
        }, 20*1000)
        
    }

    // if (Web3.givenProvider) {
    //     window.web3 = new Web3(window.ethereum);
    //     // window.ethereum.enable();
    //     // this.web3 = window.web3;
    //     this.web3 = new Web3(Web3.givenProvider || "ws://localhost:8545");

    //     this.getAccount().then((acc)=>{
    //         if(acc){
    //             this.walletEnabled$.next( true );
    //         }
    //     });
    // }
    // else{
    //     alert('Non-Binance Smart Chain browser detected. Install MetaMask');
    // }

    
  }


  public  async enableMetaMaskAccount(): Promise<any> {
    
    await new Promise((resolve, reject) => {
      this.walletEnabled$.next( window.ethereum.enable() );
      resolve(this.walletEnabled$.value);
      console.log("Metamask enabled");
    });
    return Promise.resolve(this.walletEnabled$.value);
  }

  public async getAccount(): Promise<any> {
    
    if (this.account == null) {
      this.account = await new Promise((resolve, reject) => {
        
        window.web3.eth.getAccounts((err: any, retAccount: any) => {
          if (retAccount.length > 0) {
            this.account = retAccount[0];
            resolve(this.account);
          } else {            
            reject('No accounts found.');
          }
          if (err != null) {
            reject('Error retrieving account');
          }
        });
      }) as Promise<any>;
    }
    return Promise.resolve(this.account);
  }

  public async getUserBalance(): Promise<any> {
    const account = await this.getAccount();
    return new Promise((resolve, reject) => {
      window.web3.eth.getBalance(account,  (err: any, balance: any) => {
        if (!err) {
          const retVal = {
            // tslint:disable-next-line:object-literal-shorthand
            account: account,
            // tslint:disable-next-line:object-literal-shorthand
            balance: balance
          };
          resolve(retVal);
        } else {
          reject({account: 'error', balance: 0});
        }
      });
    }) as Promise<any>;
  }
  
  // tslint:disable-next-line:typedef
  burnWAR(coin:string, amount: any, wallet: string): Promise<any> {
    const that = this;
    const smartContractAddress= '';
    return new Promise((resolve, reject) => {
      const tokenAbi = tokensAbi.get(coin);

      let decimal = 12;
      if(coin !== 'wAR'){
        decimal=0; //PBTs have decimal of 0
      }
      
      const transferContract = TruffleContract(tokenAbi);
      transferContract.setProvider(that.provider);
      transferContract.at((environment.bscCoinContractsAddresses as any)[coin]).then((instance: any) => {
        return instance.burn(amount * 10**decimal, wallet,
          {
            from: that.account,
            value: environment.turn_bsc_fee_off  ? 0 : Web3.utils.toWei(this.bscFee, 'ether') 
          });
      }).then((response: any) => {
          // console.log('response: ', response);
          if (response) {
              return resolve({receipt: response.receipt, status: true});
          }else{
              return reject('Error');
          }
      }).catch((error: any) => {
        // console.log(error);
        return reject('BSC.service error');
      });

    });
  }



}
