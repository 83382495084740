export const environment = {
  production: true,
  apiUrl: 'https://pbtgclaimapi.permabot.xyz',
  arweaveFee: 0.005,  // 0.5%
  arweaveHost: 'arweave.net',
  arweavePort: '443',
  arweaveProtocol: 'https',

  targetWalletAddress: '',

  bscCoinContractsAddresses : {
    "wAR": '',
    "PBT": ''

  },
  turn_arweave_off : true,

  turn_bsc_fee_off : true
};
