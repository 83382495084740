import { Component } from '@angular/core';
import {HostListener} from '@angular/core';
import { ArweaveService } from './services/arweave.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Claim your PBTG';

  constructor(
    private arweaveService: ArweaveService
) {
  // window.addEventListener("arweaveWalletLoaded", () => {
  //   /** Handle ArConnect load event **/
  //   console.log(`Arweave wallet loaded Yes!`);
  //   this.title="DAPP Loaded";
  // });
 }

  @HostListener('window:arweaveWalletLoaded')// , ['$event']
  onArweaveWalletLoaded() {
    // do something meaningful with it
    // console.log(`Arweave wallet loaded!`);
    this.arweaveService.setWalletLoaded(true).then(()=>{});
  }

}
