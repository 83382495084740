import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StartComponent } from './components/start/start.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import {MatButtonModule} from '@angular/material/button';
// import {MatInputModule} from '@angular/material/input';
// import {MatDatepickerModule} from '@angular/material/datepicker';
// // import {MatNativeDateModule} from '@angular/material/nativedate';
// import {MatCheckboxModule} from '@angular/material/checkbox';
// import {MatSelectModule} from '@angular/material/select';
import {MatDialogModule} from "@angular/material/dialog";

import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// used to create fake backend
// import { fakeBackendProvider } from './helpers';


import { JwtInterceptor, ErrorInterceptor, appInitializer } from './helpers';
import { AccountService } from './services';
import { AlertComponent } from './alert.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { WarToBscSummaryDialogComponent } from './components/war-to-bsc-summary-dialog/war-to-bsc-summary-dialog.component';
import { BscToWarSummaryDialogComponent } from './components/bsc-to-war-summary-dialog/bsc-to-war-summary-dialog.component';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';

// import { AlertComponent } from './components';

@NgModule({
  declarations: [
    AppComponent,
    StartComponent,
    HeaderComponent,
    FooterComponent,
    
    AlertComponent,
    AlertDialogComponent,
    
    WarToBscSummaryDialogComponent,
    
    BscToWarSummaryDialogComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    // MatButtonModule,
    // MatInputModule,
    // MatDatepickerModule,
    // // MatNativeDateModule,
    MatDialogModule,
    // MatCheckboxModule,
    // MatSelectModule,
    HttpClientModule,

    NgbModule
  ],
  providers: [
      { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AccountService] },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

        // // provider used to create fake backend
        // fakeBackendProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
