import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AccountService } from '../services';
import { from, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private accountService: AccountService
    ) { }

   /**
    * Can this route be activated?
    * @param {ActivatedRouteSnapshot} route - The route.
    * @returns {Promise<boolean>} True if user is authenticated otherwise false
    */
    public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        // const jwtToken = localStorage.getItem ('token');
        const authenticated =  this.accountService.accountValue !== null;
        if (authenticated){
            return true;
        }

        // not logged in so redirect to login page with the return url 
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}
