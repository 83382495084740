<h2 mat-dialog-title>Swap {{injectedData.title}} </h2>

<div class="row">
    <div class="col-12">
        <mat-dialog-content >
  
            <h3 *ngIf="injectedData.successful == true" class="alert alert-success">{{injectedData.message}}</h3>
            <h3 *ngIf="injectedData.successful == false" class="alert alert-danger">{{injectedData.message}}</h3>
            
        
         
        </mat-dialog-content>
        
        <mat-dialog-actions>
            <button class="mat-raised-button "(click)="close()">Close</button>
            
        </mat-dialog-actions>
    </div>
</div>
