<div class="container-fluid text-center">

  <div class="row">
    <div class="d-flex d-md-none col-12 align-content-center">
							
      <!-- Web3ads.net 320x100 -->
      <!-- <div class="_0cbf1c3d417e250a" data-zone="ff3c7ddd048048f694c54f8a332c652e" style="width:320px;height:100px;display: inline-block;margin: 0 auto"></div> -->
      <!-- /Web3ads.net 728 -->   
      
      <script type="text/javascript">
        atOptions = {
          'key' : '0186da00b7fb08ba92954f82c3a264ec',
          'format' : 'iframe',
          'height' : 250,
          'width' : 300,
          'params' : {}
        };
        document.write('<scr' + 'ipt type="text/javascript" src="http' + (location.protocol === 'https:' ? 's' : '') + '://www.topdisplayformat.com/0186da00b7fb08ba92954f82c3a264ec/invoke.js"></scr' + 'ipt>');
      </script>
             
    </div>

    <div class="d-none d-md-flex col-md-12 align-content-center ad-728">
            
      <!-- Web3ads.net 728 -->
      <!-- <div class="_0cbf1c3d417e250a" data-zone="675cfbd7f21a49c3ad4eb040511c6610" style="width:728px;height:90px;display: inline-block;margin: 0 auto"></div> -->
      <!-- /Web3ads.net 728 --> 

      <!-- Ads terra-->
      <script type="text/javascript">
        atOptions = {
          'key' : '0dfe46e1f09a3a0afddd1cf22a762b6c',
          'format' : 'iframe',
          'height' : 90,
          'width' : 728,
          'params' : {}
        };
        document.write('<scr' + 'ipt type="text/javascript" src="http' + (location.protocol === 'https:' ? 's' : '') + '://www.topdisplayformat.com/0dfe46e1f09a3a0afddd1cf22a762b6c/invoke.js"></scr' + 'ipt>');
      </script>
             
    </div>
  
  </div>

  <div class="row mb-4 mt-4">
    <div class="col-12 d-flex justify-content-center">
      <img src="/assets/images/plogo.png" class="img-fluid" style="max-width: 80px;"/>

    </div>
    <div class="col-12 text-center">
      <h2 style="color: darkblue;">Claim  PBTG for your PBT  </h2>
    </div>
    <div class="col-12 text-center d-flex justify-content-center">
      <a href="/home#howto" class="text-danger  mx-2">How to use</a>
      <div class="px-2">|</div>
      <a href="/home#about" class="text-danger ">About</a>
    </div>

    <div class="col-12 mt-4 alert  alert-success">Submissions are on till July 15, 2022</div>

    <div class="col-12 mt-4 alert  alert-info">Install <a href="https://www.arconnect.io/">ArConnect </a> to use this DAPP </div>
    
  </div>

  

  <div class="row mt-4">
    <div class="col-12 col-sm-12 mt-4">
      <div class="card text-white bg-primary">
        <div class="card-header">
          <h2 class="text-left title">PBTG for PBT (BSC)</h2>
          <div class="d-flex justify-content-between">
            
            <div class="w-100" *ngIf="( arweaveService.isWalletLoaded$ | async ) as isWalletLoaded">
              <div class="d-flex justify-content-between w-100" *ngIf="( arweaveService.walletDetails$ | async ) as walletDetails">
                <div class="overflow-text">{{walletDetails.address}}  </div>
                <div class="" *ngIf="arweaveService.pbtBalance$ | async"><em>Balance: {{(arweaveService.pbtBalance$ | async) | number :'1.0-2'}} PBT</em></div>

                <div class="" *ngIf="!(arweaveService.pbtBalance$ | async)">
                  <em>Loading... PBT</em>
                </div>
              </div>

              <div class="d-flex justify-content-between w-100" *ngIf="!( arweaveService.isWalletReady$ | async )">
                <button class="btn btn-primary arweave-button px-4 py-3" style="border-color: #f7fbf8;" (click)="connecToWallet()">
                  <img src="/assets/images/arconnect.jpg" class="img-fluid  px-1" style="max-width: 1.5rem;"/>
                  Connect with ArConnect</button>
              </div>
            </div>
            
          </div>
          
        </div>
        <div class="card-body bg-primary-faint">
          <div class="card m-3 text-white bg-primary">     
      
            <h3 class="card-header">
                <div class="d-flex justify-content-between">
                  <div>Claim </div>
                  <div><strong *ngIf="arweaveService.pbtBalance$ | async">Estimated PBTG:</strong> {{(500 * (arweaveService.pbtBalance$ | async) ) | number: '1.0-2'}} PBTG</div>
                </div>
            </h3>
            <div class="card-body">
        
                <form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()">

                  

                    <div class="form-group text-left">
                      <label class="text-left">Destination BSC Wallet Address</label>
                      <input type="text"   formControlName="destBscAddress" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.destBscAddress.errors }" />
                      <div class="text-center">
                        <small  >Address of your BSC Wallet, You can only claim your PBTGs from this address</small>
                      </div>
                      <div *ngIf="submitted && f.destBscAddress.errors" class="invalid-feedback">
                        <div *ngIf="f.destBscAddress.errors.required">BSC Wallet is required</div>
                        <div *ngIf="f.destBscAddress.errors.minlength">Invalid Wallet Address Length</div>
                      </div>
                    </div>


                    

                    
                    
      
                    <div class="form-row">
                        <div class="form-group col">
                            <button [disabled]="!( arweaveService.isWalletReady$ | async ) || loading" class="btn btn-primary arweave-button"  style="border-color: #f7fbf8;">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                Submit
                            </button>
                            
                        </div>
                        
                    </div>
                </form>
            </div>
        
          </div>

        </div>
      </div>

      
    </div>

  </div>

  <div class="row mt-8 text-how-to" id="howto">
    <div class="col-12 text-left ">
      <h2 class="title text-center mb-4" style="font-size: 2rem; font-weight: 600;">How to use</h2>

      <div class="row">
        <div class="col-12 col-sm-12">
          <div class="card">
            <div class="card-header"><strong>To claim PBTGs(on Binance Smart Chain) based on your current PBT(on Arweave) Balance</strong></div>
            <div class="card-body">
              <ol class="">
                <li class="">Click the ArConnect button to login with your ArConnect Arweave wallet</li>
                <li class="">Ensure your Arweave Wallet has PBTs</li>
                
                <li class="">Enter your destination BSC Wallet address <em>(This is the wallet you will be able to use to claim equivalent amount of PBTGs)</em></li>
                <li class="">Watch out for news on our Telegram group</li>
                <li class="">We will announce link for DAPP to claim eligible PBTGs</li>
              </ol>
            </div>
          </div>
          

          
        </div>

      </div>
    </div>
  </div>

  <div class="row mt-8 text-how-to" id="about">
    <div class="col-12 text-left ">
      <h2 class="title text-center mb-4 mt-4" style="font-size: 2rem; font-weight: 600;">About</h2>

      <div class="row">
        <div class="col-12 col-sm-12">
          <div class="card">
            
            <div class="card-body ">
              <div class="card-text text-center">
                We are rewarding every current PBT Holder with PBTGs issued on BSC. 
              </div>

              <div class="card-text text-center">
                Follow instructions above to claim Eligible PBTGs
              </div>
            </div>
          </div>
          

          
        </div>

        

      </div>
    </div>
  </div>
    
  
</div>