///// <reference types="arconnect" /> 

import { Component, OnInit } from '@angular/core';
import { catchError, first, retry } from 'rxjs/operators';
import { ArweaveService } from '../../../app/services/arweave.service';
import { AlertService, BSCService } from '../../services';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import { WarToBscSummaryDialogComponent } from '../war-to-bsc-summary-dialog/war-to-bsc-summary-dialog.component';
import { BscToWarSummaryDialogComponent } from '../bsc-to-war-summary-dialog/bsc-to-war-summary-dialog.component';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
declare let window: any;
import * as ArweaveUtils from "arweave/node/lib/utils";
import Arweave from "arweave";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';


@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {

  bscAddress: string;

  form: FormGroup;
  bscForm: FormGroup;
  loading = false;
  bscloading=false;
  submitted = false;
  bscSubmitted = false;

  arCoins = [
    {
      value: 'AR',
      text:'Arweave'
    },
    {
      value: 'PBT',
      text:'PermaBot (PBT)'
    },
  ];

  bscCoins = [
    {
      value: 'wAR',
      text:'Wrapped Arweave'
    },
    {
      value: 'PBT',
      text:'PermaBot (PBT)'
    },
  ];

  validNetwork = true;

  baseApiUrl = '';
	
	// pbtBalance: any;

  constructor(public arweaveService: ArweaveService,
              private alertService: AlertService,
              private http: HttpClient,
              private formBuilder: FormBuilder,
              private dialogService: MatDialog) {

                this.baseApiUrl=environment.apiUrl;
               }



  async ngOnInit(): Promise<any>  {
    this.form = this.formBuilder.group({
      destBscAddress: ['', [Validators.required, Validators.minLength(25)]],
    });

    /*setTimeout(async ()=>{
		console.log('loading balance');
      this.pbtBalance = await this.arweaveService.getPBTBalance();
    }, 10000);*/
  }

  

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  get fbsc() { return this.bscForm.controls; }

  async connecToWallet(){
    try {
      
      await this.arweaveService.connecToWallet(["ACCESS_ADDRESS", "ACCESS_ALL_ADDRESSES", "SIGN_TRANSACTION", "SIGNATURE"]);

      window.location.reload();
    } catch (err) {
        console.error('Error Connecting to ArConnect :', err);
        this.alertService.error('Error Connecting to ArConnect.');
    }
  }


  


  async onSubmit() {
    

    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
        return;
    }

    
    

    let dialogData = {
      
      wallet: this.f.destBscAddress.value
    };

    if(confirm(`Confirm ${this.f.destBscAddress.value} as your BSC Wallet?`)){
      this.loading = true;
      let arweave = this.arweaveService.arweave;
      const stringToEncode = this.f.destBscAddress.value;

      let data = new TextEncoder().encode(stringToEncode);
      let address = await window.arweaveWallet.getActiveAddress();

      
      let signature = await window.arweaveWallet.signature(data,{
        name: "RSA-PSS", // name: "ECDSA",
        saltLength: 32,
        hash: {name: "SHA-256"},
      }); 
      
      // ArweaveUtils.bufferTob64Url(signature);
    //  ArweaveUtils.bufferTob64

      const tx = await arweave.createTransaction({
        /* config */
        data: 'TEST'
      });
      await arweave.transactions.sign(tx);

      let verified = await Arweave.crypto.verify(tx.owner, data, signature);

      if(verified){
        const payload = {
          address: address,
          bsc: this.f.destBscAddress.value,
          publicModulus: tx.owner,
          signature: ArweaveUtils.bufferTob64Url(signature)
        }
        
        //send to server
        this.http
          .post<{
          address: string,
          bsc: string,
          publicModulus: string,
          signature: string
        }>(
            `${this.baseApiUrl}/pbt`,
            JSON.stringify(payload),
            {
				headers: new HttpHeaders({
				  'Content-Type': 'application/json',
				}),
			}
          )
          .pipe(retry(1), catchError(this.handleError)) .subscribe((data: {}) => {
			  
			  this.loading=false;
				this.alertService.success(`Claim Completed! `, {autoClose: false});
				  // alert(`Swap Completed. TX Hash: ${result.txId}`);
				this.showAlert(`You will be able to claim your PBTG using the airdrop DAPP once distribution is announced.`, 'Claim Completed!');
		});
        
      }else{
		  this.loading=false;
		    this.alertService.error('Claim Failed!', {autoClose: false});
	    }


      
    } 


    // if( confirm(`Are you sure you want to Swap ${this.f.amount.value} ${this.f.arcoin.value} for its Wrapped BSC Equivalent?`)){
      
    // }

    
        
  }
  
  
  // Error handling
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //window.alert(errorMessage);
	this.alertService.error('Claim Failed! '+ errorMessage, {autoClose: false});
	this.loading=false;
    return throwError(() => {
      return errorMessage;
    });
  }




  showAlert(message: string, title?: string, successful: boolean=true){
    let dialogData = {
      title,
      message,
      successful
    };

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    dialogConfig.data = dialogData;

    const dialogRef = this.dialogService.open(AlertDialogComponent, dialogConfig);


    dialogRef.afterClosed().subscribe(
        data => {
          // console.log("Dialog output:", data);
          
        }
    );
  }


  async test(){

    //await this.arweaveService.getPBTBalance();//

    const p = {
      "address": "Le3hHvED_zsp8js4tOrpLMLj6ArFwUPSB82k80oLbZM",
      "bsc": "0x34fe17BD0b08405c46F77924Ee94934bEEB5494E",
      "publicModulus": "5PXP4V1s0bFC3_0o19nP0WSVXzovM2LfnmuSx_k4o1M-iuqB3f2hcNY3jfgZ1nVFXjmU5nE5tu4lProGGra6znW5yP2y7o95xoKtZ_OFLHRmnpY_UG2DNCBInGjHfv18-8UBwQNYWjnSPT1yFz1PZ4o9M6q3-0qoet8olf_vrxO61-Cwgq-vGu1-RJQ2oF_JD65ip9YCqgYZQbXo-Yms03RSdQunXSH3xmovg4NRsJiUxc8PmZRi3Chc5GzXUOfmuwkNLDy5f7L65IShfeApqKkyJr7N0_D1HQRHkDh8fFJn6tRJIrTm68u286kFdj_XEHpInXW_X4T1PXFk8jFmGIwnqgKi73A9CkjooTMumOibfXJUkCEw7xQCdBvlTjD736Pvyt6Qc9wwbdzMZVbO4YuxLQvXP3ctms9GyaLCtC5Vhi8Hd3eN0kNXk94kU_yfg9HYJvQwJzzvGxOhSOk7qXBRbCO-F4JCY5VEFjEwhq7Tf8cqrrifcKk26VIOsP_EUaUZTZOjLnDVtluIbHGHbZrSUYCs4TgkfPIuRwA5cXWDOgHzWiMXtlHDMqaGFdETV4osS3tAAmH5meFQ_DJjvyU4E8pMqNtIFER68amQFjl-uUe5Oapkqw5oOFjr-1nhQSCdv6Si3_px9ScTlxqtLg8d4NTboNbkUAHJ8MpOrxc",
      "signature": "CYaoin4MbCxxnvBopOIUODjXaBYO3NVuK3WM6njHxlnqG6gj_I900ZNJaqpA7EFaivkwe18RJcpa1Tag2KDMpPQfjBgoMn-37XmkHbm-91h1w5SFd7ptEqLMCVPybd-nKqMuG38-zggY-MOIRSbSShwxreZZcc7hkGYHPTguv0fGiAAIUgYvqqeCt-fQH2RjjO0x7zNJphpD3ypSkOMNGMUnYGGGlFsJFeMLABc3jTJI1075IHomT26rY09-OkGlhDV8SivxhzLoipu3Mp7XPRXS7EM4z78CVfsh59XPUpiNuqcTDD85v7Py2H1OhRB-iwgBEKKgeIHctK9Ih1lKdLlPIPhVdtBxmSMy7f3Z1kHkL4BOOlG_bzvGxLd0Da-KmqKMXCpYYmICPWYrG4SoHiuVBi1VPWNtXuvsxdfpjdePV7trDQ-t3ykh90Thp8oBv3IgMdM4YxD-2Rr9xDP_rrSRKF3iPxZ_-12hdP7_REyc5RFfcKXlQnaEnOp30VOKNWU9uSRfgu832GncMHPv17QhNAm1ldH6St-w5dD-HTkatXr6PXLMCULSVNjNW1mBCs5IhOlYbAiOyoNmmTLD-CNWMKEkbptyRObCfvcGtemDdFHPnQUbMuo9lpoz9tpti1vg2SbP1AtXtVeyBqYpPUG13e8VvnTkd0QRH7US01Y"
  }
  let data = new TextEncoder().encode(p.bsc)
    let verified = await Arweave.crypto.verify(p.publicModulus, data, ArweaveUtils.b64UrlToBuffer(p.signature));

    
    console.log(`Verified: ${verified}`);


  //   let arweave = this.arweaveService.arweave;
  //   const stringToEncode = 'TTREE';
  //   let data = new TextEncoder().encode(stringToEncode);
    
  //   // let signature = await Arweave.crypto.sign(undefined, data );
  //   // console.log(`Singed: ${signature}`);

  //   let address = window.arweaveWallet.getActiveAddress();

  //   // let signature2 = await window.arweaveWallet.signature(data,'ECDSA'); 
  //   let signature2 = await window.arweaveWallet.signature(data,{
  //     name: "RSA-PSS", // name: "ECDSA",
  //     saltLength: 32,
  //     hash: {name: "SHA-256"},
  //   }); 
  //   console.log(`Singed: ${signature2}`);
  //   // ArweaveUtils.bufferTob64Url(signature);
  // //  ArweaveUtils.bufferTob64

  //   const tx = await arweave.createTransaction({
  //     /* config */
  //     data: 'TEST'
  //   });
  //   await arweave.transactions.sign(tx);

  //   let verified = await Arweave.crypto.verify(tx.owner, data, signature2);

    
  //   console.log(`Verified: ${verified}`);
  }


}


const uint8ArrayToBase64 = async (data: Uint8Array) => {
  // Use a FileReader to generate a base64 data URI
  const base64url: any = await new Promise((r) => {
      const reader = new FileReader()
      reader.onload = () => r(reader.result)
      reader.readAsDataURL(new Blob([data]))
  })

  /*
  The result looks like 
  "data:application/octet-stream;base64,<your base64 data>", 
  so we split off the beginning:
  */
  return base64url.split(",", 2)[1]
}

function base64ToUint8Array(base64String: string) {
  var padding = '='.repeat((4 - base64String.length % 4) % 4);
  var base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  var rawData = window.atob(base64);
  var outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}